import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import InputGlobal from "shared/components/Form/InputGlobal";
import RadioButton from "shared/components/Form/RadioButton";
import Select from "shared/components/Form/Select";
import SpinLoader, {
  LOADER_COLOR_DARK,
  LOADER_SIZE_LARGE,
  POSITION_ABSOLUTE,
} from "shared/components/SpinLoader";

import {
  ERROR_STATUS_NOT_CONFIRM,
  ERROR_STATUS_UNPROCESSABLE,
  GENDER_FEMALE,
  GENDER_MALE,
} from "shared/constants";

import { registrationFailedEvent, simplyClick } from "shared/helpers/analytics";
import {
  afterRegistrationSuccess,
  prepareRegistrationData,
} from "shared/helpers/auth";
import {
  getBirthdayFromRegDate,
  getBirthdayInFormat,
  getDate,
  getMonths,
  getYearByKey,
  getYears,
} from "shared/helpers/date";
import { isFullDataUserValidate } from "shared/helpers/validation";

import { registrationUser } from "shared/services/auth";

import closeIconImg from "assets/shared/img/ic_close.svg";

export const AboutYou = ({ dataGoogle, active, setIsShowing }) => {
  const [showBlock, setShowBlock] = useState({
    name: false,
    gender: false,
    birthday: false,
  });
  const [loader, setLoader] = useState(false);
  const [responseError, setResponseError] = useState(null);
  const [responseErrorDate, setResponseErrorDate] = useState(null);
  const [currentData, setCurrentData] = useState({});
  const [globalError, setGlobalError] = useState({});

  const { t } = useTranslation("auth");

  useEffect(() => {
    let birthdayData = {};
    if (dataGoogle?.birthday) {
      birthdayData = getBirthdayFromRegDate(dataGoogle?.birthday);
    }

    const fullData = {
      ...currentData,
      ...dataGoogle,
      ...birthdayData,
    };

    setCurrentData({
      ...fullData,
      ...{ gender: dataGoogle?.gender ?? +GENDER_MALE },
    });
    const resultValidation = isFullDataUserValidate(fullData);

    setShowBlock({
      name: resultValidation.errors?.first_name,
      gender: resultValidation.errors?.gender,
      birthday:
        resultValidation.errors?.birthday ||
        resultValidation.errors?.month ||
        resultValidation.errors?.year ||
        resultValidation.errors?.day,
    });
  }, [dataGoogle, active]);

  /**
   * Close && clean Google register form
   */
  const closeGoogleModal = () => {
    setIsShowing(false);
    document.body.style.overflow = "auto";
    setGlobalError({});
    setResponseError(null);
    setResponseErrorDate(null);
    setCurrentData({});
  };

  /**
   * Global Handler
   * @param {string || null} name
   * @param {string || number} value
   */
  const handlerFieldChanged = (name, value) => {
    setGlobalError({ ...globalError, ...{ [name]: null } });
    setCurrentData({ ...currentData, ...{ [name]: value } });
  };

  const postRegisterDataGoogle = (data) => {
    setLoader(true);
    setTimeout(() => {
      registrationUser(prepareRegistrationData(data))
        .then((resp) => responseCb(resp))
        .catch((err) => responseErrorCb(err));
    }, 50);
  };

  const handleSubmitGoogle = () => {
    const { valid, errors } = isFullDataUserValidate(currentData);

    if (valid) {
      postRegisterDataGoogle({
        ...currentData,
        birthday: getBirthdayInFormat(
          currentData.year,
          currentData.month,
          currentData.day
        ),
      });
    } else {
      setGlobalError(errors);
      registrationFailedEvent(true);
    }
  };

  /**
   * Success request on api
   * @param {object} response success request on api
   */
  const responseCb = (response) => {
    afterRegistrationSuccess(
      response,
      true,
      () => setLoader(false),
      () => setLoader(false)
    );
  };

  /**
   * Failed request on api
   * @param {object} responseErr failed request on api
   */
  const responseErrorCb = (responseErr) => {
    registrationFailedEvent(true);

    if (responseErr.response.status === ERROR_STATUS_UNPROCESSABLE) {
      setLoader(false);

      const { data } = responseErr.response;
      let errorText = "";

      data.errors.forEach((error) => {
        if (error.name === "email") {
          setResponseError(error.message);
          simplyClick("err_lnd_sign_up_validation_againgoogle", null);
        } else if (error.name === "birthday") {
          setResponseErrorDate(error.message);
        } else if (error.name !== "email") {
          errorText += error > 0 ? " " + error.message : error.message;
        }
      });

      if (errorText !== "") {
        setResponseError(errorText);
      }
      return;
    } else if (responseErr.response.status === ERROR_STATUS_NOT_CONFIRM) {
      window.location.href = "/moderation/";
      return;
    }
    setResponseError(t("cantRegisterProfileError"));
    setLoader(false);
  };

  if (!active) {
    return null;
  }

  return (
    <>
      <div className={"google-modal" + (active ? " show" : "")}>
        {loader && (
          <SpinLoader
            color={LOADER_COLOR_DARK}
            size={LOADER_SIZE_LARGE}
            position={POSITION_ABSOLUTE}
            withBlur={true}
            isFormBlur={true}
            customClass={"about__you"}
          />
        )}
        <div className="google-wrap">
          <div className="google-header">
            <span className="title">{t("aboutYou")}</span>
            <span onClick={closeGoogleModal} className="close__icon">
              <img src={closeIconImg} alt="close-icon" />
            </span>
          </div>
          <div className="google-body">
            {showBlock.name && (
              <>
                <span className="label_global">{t("nameIsLabel")}</span>
                <InputGlobal
                  className={"register__form"}
                  handleChange={(event) =>
                    handlerFieldChanged("first_name", event?.target?.value)
                  }
                  name="name-google"
                  value={currentData.first_name}
                  error={!!globalError.first_name}
                  errorText={globalError.first_name ?? ""}
                  placeholder={t("placeholderName")}
                />
              </>
            )}
            {showBlock.gender && (
              <>
                <span className="label_global">{t("IamLabel")}</span>
                <div className="gender_wrap">
                  <div className="gender_wrap-item">
                    <RadioButton
                      name={"gender-google"}
                      label="Man"
                      id={"radio-google1"}
                      value={GENDER_MALE}
                      cb={(value) => handlerFieldChanged("gender", +value)}
                      isSelected={currentData.gender === GENDER_MALE}
                      isForm={true}
                    />
                  </div>

                  <div className="gender_wrap-item">
                    <RadioButton
                      name={"gender-google"}
                      label="Woman"
                      id={"radio-google2"}
                      value={GENDER_FEMALE}
                      cb={(value) => handlerFieldChanged("gender", +value)}
                      isSelected={currentData.gender === GENDER_FEMALE}
                      isForm={true}
                    />
                  </div>
                </div>
              </>
            )}
            {showBlock.birthday && (
              <>
                <span className="label_global">{t("birthdayLabel")}</span>
                <div className="birthday__wrap">
                  <Select
                    className={globalError.month ? "error__register" : ""}
                    customClassList="date_item"
                    values={getMonths()}
                    value={getMonths()[currentData.month] ?? ""}
                    handleChange={(value) =>
                      handlerFieldChanged("month", +value)
                    }
                    name="month_google"
                    placeholder={t("monthPlaceholder")}
                    errorText={globalError.month}
                  />
                  <Select
                    className={globalError.day ? "error__register" : ""}
                    customClassList="date_item"
                    values={getDate()}
                    value={getDate()[currentData.day] ?? ""}
                    handleChange={(value) => handlerFieldChanged("day", +value)}
                    name="day_google"
                    placeholder={t("dayPlaceholder")}
                    errorText={globalError.day}
                  />
                  <Select
                    className={globalError.year ? "error__register" : ""}
                    customClassList="date_item"
                    values={getYears()}
                    value={currentData.year ?? ""}
                    handleChange={(value) =>
                      handlerFieldChanged("year", getYearByKey(value))
                    }
                    name="year_google"
                    placeholder={t("yearPlaceholder")}
                    errorText={globalError.year}
                  />
                  {responseErrorDate && (
                    <p className="error date">{responseErrorDate}</p>
                  )}
                </div>
              </>
            )}
            <div className="form_footer">
              <button onClick={handleSubmitGoogle} className="form__button">
                {t("signUpTitle")}
              </button>
              {responseError && (
                <p className="error response">{responseError}</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
