/*
 * Core
 * */
import { useState } from "react";
import { useTranslation } from "react-i18next";

import GoogleSignUp from "shared/components/Auth/GoogleSignUp";
import SpinLoader, {
  LOADER_COLOR_DARK,
  LOADER_SIZE_LARGE,
  POSITION_ABSOLUTE,
} from "shared/components/SpinLoader";

import {
  ERROR_STATUS_NOT_CONFIRM,
  ERROR_STATUS_UNPROCESSABLE,
  GENDER_MALE,
} from "shared/constants";
import {
  errorEventsBirthday,
  errorEventsName,
  getErrorEventEmail,
  getErrorEventPassword,
} from "shared/constants/auth.js";

import { registrationFailedEvent, simplyClick } from "shared/helpers/analytics";
import {
  afterRegistrationSuccess,
  prepareRegistrationData,
} from "shared/helpers/auth";
import { setCookie } from "shared/helpers/cookie";
import { getBirthdayInFormat } from "shared/helpers/date";
import {
  isValidGender,
  validateAge,
  validateEmail,
  validateName,
  validatePassword,
} from "shared/helpers/validation";

import { registrationUser } from "shared/services/auth";

import { PersonalInfo } from "./PersonalInfo";
import { SignInInfo } from "./SignInInfo";

const RegistrationForm = ({ isModal = false }) => {
  const [step, setStep] = useState(1);
  const [data, setData] = useState({
    gender: GENDER_MALE,
  });
  const [errorName, setErrorName] = useState(null);
  const [errorGender, setErrorGender] = useState(null);
  const [termsError, setTermsError] = useState(null);
  const [errorDate, setErrorDate] = useState(null);
  const [errorDay, setErrorDay] = useState(false);
  const [errorMonth, setErrorMonth] = useState(false);
  const [errorYear, setErrorYear] = useState(false);
  const [checkBox, setCheckBox] = useState(false);
  const [emailError, setEmailError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const [repeatPasswordError, setRepeatPasswordError] = useState(null);
  const [responseError, setResponseError] = useState(null);
  const [loader, setLoader] = useState(false);

  const { t } = useTranslation("auth");

  /**
   * Handler for validate form and send request on api
   */
  const handleClickContinue = () => {
    const USERNAME_REGEX = /^[a-zA-Z\s’'-]+$/;
    let isSuccessValidation = true;

    if (
      !data.gender ||
      !data.first_name ||
      !USERNAME_REGEX.test(data.first_name) ||
      data.first_name.length < 2 ||
      !checkBox ||
      !data.month ||
      !data.day ||
      !data.year
    ) {
      isSuccessValidation = false;

      const resultGender = isValidGender(data.gender);

      if (!resultGender) {
        setErrorGender(t("genderError"));
      }

      const { error, status = { text: "", type: "" } } = validateName(
        data?.first_name ?? ""
      );

      if (!status) {
        setErrorName(error.text);
      }

      if (error.type) {
        simplyClick(errorEventsName[error.type], null, false);
      }

      const resultAge = validateAge(data.month, data.day, data.year);

      const dateKey = [
        data.month ? "M" : "",
        data.day ? "D" : "",
        data.year ? "Y" : "",
      ].join("");

      if (!resultAge.status) {
        setErrorDate(resultAge.error);
      }

      if (!data.day && !data.month && !data.year) {
        simplyClick("err_lnd_sign_up_age_nodate", null, false);
      }

      // Only part of the date is entered
      if (errorEventsBirthday[dateKey]) {
        simplyClick(errorEventsBirthday[dateKey], null, false);
      }

      if (!data.month) {
        setErrorMonth(true);
      }
      if (!data.day) {
        setErrorDay(true);
      }
      if (!data.year) {
        setErrorYear(true);
      }

      if (!checkBox) {
        simplyClick("err_lnd_sign_up_checkbox_nocheckbox", null, false);
        setTermsError(t("termsError"));
      }
    } else if (step === 1) {
      const { error, status = { text: "", type: "" } } = validateName(
        data?.first_name ?? ""
      );

      if (!status) {
        setErrorName(error.text);
        return;
      }

      setData({
        ...data,
        birthday: getBirthdayInFormat(data.year, data.month, data.day),
      });

      setStep(step + 1);
    } else if (step === 2) {
      const resultEmail = validateEmail(data.email);

      if (!resultEmail.status && resultEmail.error) {
        simplyClick(
          getErrorEventEmail(resultEmail.error, "sign_up"),
          null,
          false
        );
        setEmailError(resultEmail.error);
        isSuccessValidation = false;
      }

      const resultPassword = validatePassword(
        data.password,
        data.repeat_password
      );

      if (resultPassword.errorPassword.type) {
        simplyClick(
          getErrorEventPassword(resultPassword.errorPassword.type, "sign_up"),
          null
        );
      } else if (resultPassword.errorRepeatPass.type) {
        simplyClick(
          getErrorEventPassword(resultPassword.errorRepeatPass.type, "sign_up"),
          null
        );
      }

      if (!resultPassword.status) {
        setPasswordError(resultPassword.errorPassword.text);
        setRepeatPasswordError(resultPassword.errorRepeatPass.text);
        isSuccessValidation = false;
      }

      if (isSuccessValidation) {
        // simplyClick(window.simplyEvent.cl_wizard_reg_form_start, null, false);
        setLoader(true);
        registrationUser(prepareRegistrationData(data))
          .then((resp) => responseCb(resp))
          .catch((err) => responseErrorCb(err));
      }
    }

    if (!isSuccessValidation) {
      registrationFailedEvent();
    }
  };

  /**
   * Success request on api
   * @param {object} response success request on api
   */
  const responseCb = (response) => {
    afterRegistrationSuccess(
      response,
      false,
      () => {
        setData({ gender: GENDER_MALE });
        setCookie("site_consent", true);
      },
      () => setLoader(false)
    );
  };

  /**
   * Failed request on api
   * @param {object} responseErr failed request on api
   */
  const responseErrorCb = (responseErr) => {
    setLoader(false);
    registrationFailedEvent();

    if (responseErr.response.status === ERROR_STATUS_UNPROCESSABLE) {
      const { data } = responseErr.response;
      let errorText = "";

      data.errors.forEach((error) => {
        if (error.name === "email") {
          setEmailError(error.message);
          simplyClick("err_lnd_sign_up_validation_again", null);
        } else if (error.name === "first_name") {
          setStep(1);
          setErrorName(error.message);
        } else if (error.name === "gender") {
          setStep(1);
          setErrorGender(error.message);
        } else if (error.name === "birthday") {
          setStep(1);
          setErrorDate(error.message);
        } else if (error.name === "password") {
          setPasswordError(error.message);
        } else {
          errorText +=
            errorText.length > 0 ? " " + error.message : error.message;
        }
      });

      if (errorText.length !== 0) {
        setResponseError(errorText);
      }
      return;
    } else if (responseErr.response.status === ERROR_STATUS_NOT_CONFIRM) {
      window.location.href = "/moderation/";
      return;
    }
    setStep(1);
    setResponseError(t("cantRegisterProfileError"));
  };

  /*
   * handler for step back
   * */
  const handleStepBack = () => {
    setStep(step - 1);
  };

  /*
   * function for return Jsx form header
   * */
  const headerForm = () => {
    if (step === 1) {
      return (
        <>
          <span className="form__title">{t("signUpTitle")}</span>
          <div className="steps__wrap">
            <span className={step ? "active" : ""}>{step}</span>
            <span>/2</span>
          </div>
        </>
      );
    } else {
      return (
        <>
          <span onClick={handleStepBack} className="arrow__back"></span>
          <span className="form__title">{t("signUpTitle")}</span>
          <div className="steps__wrap">
            <span className={step ? "active" : ""}>{step}</span>
            <span>/2</span>
          </div>
        </>
      );
    }
  };

  /*
   * function for return Jsx form body
   * */
  const bodyForm = () => {
    if (step === 1) {
      return (
        <PersonalInfo
          data={data}
          setData={setData}
          isModal={isModal}
          errorGender={errorGender}
          setErrorGender={setErrorGender}
          errorName={errorName}
          setErrorName={setErrorName}
          errorDate={errorDate}
          setErrorDate={setErrorDate}
          errorDay={errorDay}
          setErrorDay={setErrorDay}
          errorMonth={errorMonth}
          setErrorMonth={setErrorMonth}
          errorYear={errorYear}
          setErrorYear={setErrorYear}
          termsError={termsError}
          setTermsError={setTermsError}
          checkBox={checkBox}
          setCheckBox={setCheckBox}
          responseError={responseError}
        />
      );
    } else {
      return (
        <SignInInfo
          data={data}
          setData={setData}
          emailError={emailError}
          setEmailError={setEmailError}
          passwordError={passwordError}
          setPasswordError={setPasswordError}
          repeatPasswordError={repeatPasswordError}
          setRepeatPasswordError={setRepeatPasswordError}
        />
      );
    }
  };

  return (
    <>
      {isModal && <div className="background__modal"></div>}
      <div className="wrapper">
        {loader && (
          <SpinLoader
            color={LOADER_COLOR_DARK}
            size={LOADER_SIZE_LARGE}
            position={POSITION_ABSOLUTE}
            withBlur={true}
            isFormBlur={true}
          />
        )}
        <div className="form__container">
          <div className={"form_header" + (isModal ? " modal" : "")}>
            {isModal && <span className="close__icon"></span>}
            {headerForm()}
            {import.meta.env.VITE_APP_REG_ADDITIONAL_INFO && step === 1 && (
              <span className="livee-text">
                {t("common:registerFormText1", {
                  brandName: import.meta.env.VITE_APP_MULTI_SITE_BRAND_NAME,
                })}
              </span>
            )}
          </div>

          <div className="form_body">{bodyForm()}</div>
          <div className="form_footer">
            {import.meta.env.VITE_APP_REG_ADDITIONAL_INFO ? (
              <button onClick={handleClickContinue} className="form__button">
                {step === 1 ? t("tryFree") : t("withEmail")}
              </button>
            ) : (
              <button onClick={handleClickContinue} className="form__button">
                {step === 1 ? t("continueButton") : t("getStartedButton")}
              </button>
            )}

            {responseError && <p className="error response">{responseError}</p>}

            {import.meta.env.VITE_APP_GOOGLE_AUTH_CLIENT_ID && (
              <>
                <span className="line"></span>
                <GoogleSignUp
                  data={data}
                  setData={setData}
                  setLoader={setLoader}
                  setResponseError={setResponseError}
                  setEmailError={setEmailError}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default RegistrationForm;
